<template>
  <div class="validate__header">
    <span>{{ $t('validateEmail.title') }}</span>
  </div>
  <div class="validate__content">
    <div
      v-if="isLoading"
      class="loader"
    ></div>
    <div
      v-show="!isLoading && !isErrored && !alreadyValidated"
      ref="checkmarkContainer"
      class="checkmark_container"
    ></div>
    <h1>{{ contentText }}</h1>
  </div>
</template>

<script>
import axios from 'axios';
import lottie from 'lottie-web';

export default {
  name: 'ValidateEmail',
  data() {
    return {
      isLoading: true,
      isErrored: false,
      alreadyValidated: false,
      token: '',
    }
  },
  async created() {
    this.token = this.$route.query.token;
    await this.validateEmail();

    lottie.loadAnimation({
      container: this.$refs.checkmarkContainer,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: "/validate_animation.json",
    });
  },
  computed: {
    contentText() {
      if (this.isLoading) {
        return this.$t('validateEmail.loading');
      }
      if (this.alreadyValidated) {
        return this.$t('validateEmail.alreadyValidated');
      }
      if (this.isErrored) {
        return this.$t('validateEmail.error');
      }

      return this.$t('validateEmail.success');
    }
  },
  methods: {
    validateEmail() {
      axios
        .post(`https://nohyia-fc0806dfbe46.herokuapp.com/auth/validateEmail/${this.token}`)
        .then(() => {
          this.isLoading = false;
          this.alreadyValidated = false;
          this.isErrored = false;
        })
        .catch((error) => {
          if (error.response?.data?.reason === 'email_already_validated') {
            this.alreadyValidated = true;
          }
          this.isErrored = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>

<style lang="scss">
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.checkmark_container {
  max-width: 200px;
  max-height: 200px;
}

.validate {
  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }
}
</style>