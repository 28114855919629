<template>
  <div class="header-section">
    <img src="@/assets/background-main-two.png" alt="Nohyia logo" class="header-section__background" />
    <div class="header-section__content">
      <span class="header-section__header__logo">{{ $t('name') }}</span>

      <div class="header-section__content__info">
        <h1
          class="header-section__content__info--label"
          v-html="$t('headerTitle')"
        />
        <div class="header-section__store-labels">
          <StoreLabel @click="redirectToAppleStore" storeName="apple" class="link" />
          <StoreLabel @click="redirectToPlayStore" storeName="google" class="link" />
        </div>
      </div>

      <div class="header-section__store-labels margin-top-25">
        <img 
          src="@/assets/social-instagram.png"
          width="30"
          height="30"
          @click="goToInstagram"
          class="link social"
          :alt="$t('instagramLink')"
        />
        <img
          src="@/assets/social-threads.png"
          width="30"
          height="30"
          @click="goToThread"
          class="link social"
          :alt="$t('threadLink')"
        />
      </div>
    </div>

    <div class="header-section__content__screen-shot">
      <img src="@/assets/main-page.png" width="90%" :alt="$t('mainPageAlt')" />
    </div>
  </div>
</template>

<script setup>
import StoreLabel from '../../components/StoreLabel.vue';

const redirectToAppleStore = () => {
  window.location.href = "https://apps.apple.com/us/app/nohyia/id6478467924";
}

const redirectToPlayStore = () => {
  window.location.href = "https://play.google.com/store/apps/details?id=com.exemple.nohyia&hl=en";
}

const goToInstagram = () => {
  window.location.href = "https://www.instagram.com/nohyiafr/";
}

const goToThread = () => {
  window.location.href = "https://www.threads.net/@nohyiafr";
}
</script>

<style lang="scss">
.header-section__content__screen-shot {
  img {
    max-width: 350px;
  }
}

.link {
  cursor: pointer;
}

.main-color {
  color: #FABC2A;
}

.margin-top-25 {
  margin-top: 25px;
  color: white;
}

.header-section {
  height: 100vh;
  position: relative;
  background-color: black;
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: center;

  @media (max-width: 650px) {
    align-items: baseline;
  }

  &__store-labels {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__header {
    &__logo {
      font-size: 60px;
      font-weight: 900;
      color: #FFF;

      @media (max-width: 650px) {
        font-size: 40px;
        padding-left: 0;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.6;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding: 20px 30px 20px 50px;
    width: 50%;

    @media (max-width: 770px) {
      width: 100%;
    }
    
    @media (max-width: 650px) {
      padding-left: 0;
      width: 100%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 650px) {
        align-items: flex-start;
        text-align: left;
      }

      &--label {
        font-size: 40px;
        font-weight: 600;
        color: white;
        margin-bottom: 50px;

        @media (max-width: 650px) {
          font-size: 40px;
        }
      }
    }

    &__screen-shot {
      display: flex;
      justify-content: center;
      width: 50%;
      z-index: 1;

      @media (max-width: 770px) {
        display: none;
      }
    }
  }
}
</style>
