<template>
  <div class="delete-account__content">
    <span class="delete-account__content__title">{{ $t('deleteAccountTitle') }}</span>
    <span class="delete-account__content__text">{{ $t('contactIfNeedHelp') }}<b>contact@nohyia.com</b>.</span>
    <span class="delete-account__content__text spacing">{{ $t('deleteAccountSubTitle') }}</span><br/>

    <span class="delete-account__content__subtitle">{{ $t('deleteAccountDescription') }}</span>

    <span class="delete-account__content__text">
        -&nbsp;&nbsp;{{ $t('deleteAccountStepOne') }}<br/>
        -&nbsp;&nbsp;{{ $t('deleteAccountStepTwo') }}<br/>
        -&nbsp;&nbsp;{{ $t('deleteAccountStepThree') }}<br/>
        -&nbsp;&nbsp;{{ $t('deleteAccountStepFour') }}<br/>
    </span>
  </div>
</template>
  
  <script>
  export default {
    name: 'DeleteAccountPage',
  }
  </script>
  
  <style lang="scss">
  .spacing {
    margin-top: 10px;
  }
  
  .delete-account {
    &__content {
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
  
      &__title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
  
      &__subtitle {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
  
      &__text {
        font-size: 14px;
      }
    }
  }
  </style>
  