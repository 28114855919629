<template>
  <div class="discover-section">
    <div class="discover-section__description">
      <h2
        class="discover-section__description__title"
        v-html="$t('discoverTitle')"
      />
      <span class="discover-section__description__title--sub">
        {{ $t('discoverSubtitle') }}
      </span>
    </div>
    <div class="discover-section__mock-up">
      <img src="@/assets/mockup-discover.png" :alt="$t('mockUpDiscover')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscoverPartPresentation',
}
</script>

<style lang="scss" scoped>
.discover-section {
  display: flex;
  flex-direction: row;
  padding: 50px 80px;
  background-color: black;

  @media (max-width: 865px) {
    flex-direction: column;
    padding: 50px 30px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    width: 100%;
    margin-left: 30px;

    @media (max-width: 865px) {
      margin-left: 0;
    }

    &__title {
      font-size: 40px;
      font-weight: 600;
      margin: 0;

      &--sub {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
      }
    }
  }

  &__mock-up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 865px) {
      margin-top: 50px;
    }

    img {
      width: 60%;
      min-width: 240px;
      object-fit: cover;

      @media (max-width: 865px) {
        width: 240px;
      }
    }
  }
}
</style>