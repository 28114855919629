<template>
  <div class="cgu">
    <div class="cgu__header">
      <div
        class="cgu__fixed-header"
        @click="$router.push('/')"
      >
        <span class="cgu__fixed-header__logo">{{ $t('name') }}</span>
      </div>
      <span>{{ $t('cgu') }}</span>
    </div>
    <div class="cgu__content">
      <div class="cgu__panel">
        <span class="cgu__panel--summary">{{ $t('summary') }}</span>
        <span
          v-for="(politic, index) in politics"
          :key="index"
          class="cgu__panel--label"
        >
          <a
            :href="`#collapse-${index}`"
            class="cgu__panel--link"
          >
            {{politic.label}}
          </a>
        </span>
      </div>
      <div class="cgu__body">
        <div
          v-for="(politic, index) in politics"
          :key="index"
          :id="`collapse-${index}`"
          class="cgu__collapse"
        >
          <div
            :id="`cgu__collapse__title-${index}`"
            class="cgu__collapse__title"
          >
            <span>{{politic.label}}</span>
          </div>
          <div
            :id="`collapse-container-${index}`"
            class="cgu__collapse__container expend"
          >
            <div
              class="cgu__collapse__content"
            >
              <span v-html="politic.value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CguTerms',
  computed: {
    politics() {
      return [
        {label: this.$t('cguPage.one.label'), value: this.$t('cguPage.one.description')},
        {label: this.$t('cguPage.two.label'), value: this.$t('cguPage.two.description')},
        {label: this.$t('cguPage.three.label'), value: this.$t('cguPage.three.description')},
        {label: this.$t('cguPage.four.label'), value: this.$t('cguPage.four.description')},
        {label: this.$t('cguPage.five.label'), value: this.$t('cguPage.five.description')},
        {label: this.$t('cguPage.six.label'), value: this.$t('cguPage.six.description')},
        {label: this.$t('cguPage.seven.label'), value: this.$t('cguPage.seven.description')},
        {label: this.$t('cguPage.height.label'), value: this.$t('cguPage.height.description')},
        {label: this.$t('cguPage.nine.label'), value: this.$t('cguPage.nine.description')},
        {label: this.$t('cguPage.ten.label'), value: this.$t('cguPage.ten.description')},
        {label: this.$t('cguPage.eleven.label'), value: this.$t('cguPage.eleven.description', {email: 'contact@nohyia.com'})},
        {label: this.$t('cguPage.twelve.label'), value: this.$t('cguPage.twelve.description')},
        {label: this.$t('cguPage.thirteen.label'), value: this.$t('cguPage.thirteen.description')},
        {label: this.$t('cguPage.fourteen.label'), value: this.$t('cguPage.fourteen.description')},
        {label: this.$t('cguPage.fifteen.label'), value: this.$t('cguPage.fifteen.description')},
        {label: this.$t('cguPage.sixteen.label'), value: this.$t('cguPage.sixteen.description')},
        {label: this.$t('cguPage.seventeen.label'), value: this.$t('cguPage.seventeen.description', {email: 'contact@nohyia.com'})},
        {label: this.$t('cguPage.heighteen.label'), value: this.$t('cguPage.heighteen.description')},
        {label: this.$t('cguPage.nineteen.label'), value: this.$t('cguPage.nineteen.description')},
        {label: this.$t('cguPage.twenty.label'), value: this.$t('cguPage.twenty.description')},
      ];
    }
  },
}
</script>

<style lang="scss">
.cgu {
  &__fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 40px;
      font-weight: bold;
      color: #FABC2A;
      cursor: pointer;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    background-color: #FABC2A;
    justify-content: flex-start;
    padding: 50px 20px 0 30px;

    @media (max-width: 780px) {
      display: none;
    }

    &--summary {
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 25px;
    }

    &--label {
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &--link {
      color: black;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 95%;
  }

  &__collapse {
    border-radius: 5px;
    border: solid 2px black;
    margin-bottom: 25px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    &__content {
      padding: 5px 20px 20px 20px;
    }
  }
}
</style>