<template>
  <div class="store__container">
    <img v-if="storeName == 'apple'" src="@/assets/apple-logo.png" width="30" height="30" />
    <img v-else src="@/assets/google-play.png" width="30" height="30" />
    <div class="store__container__text-content">
      <span>{{ appStoreGetIt }}</span>
      <span class="store__container__text-content--bold">{{ appStoreName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreLabel',
  props: {
    storeName: {
      type: String,
      default: 'google',
    }
  },
  computed: {
    appStoreGetIt() {
      return this.storeName == 'apple' ? this.$t('appStore.apple.getIt') : this.$t('appStore.google.getIt');
    },
    appStoreName() {
      return this.storeName == 'apple' ? this.$t('appStore.apple.name') : this.$t('appStore.google.name');
    },
  }
}
</script>

<style lang="scss" scoped>
.store__container {
  display: flex;
  flex-direction: row;
  padding: 6px 20px 6px 10px;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  gap: 10px;

  @media (max-width: 650px) {
    padding: 6px 10px;
    gap: 5px;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
    color: black;
    font-size: 16px;
    line-height: 1.2;

    @media (max-width: 650px) {
      font-size: 12px;
    }

    &--bold {
      font-weight: bold;
    }
  }
}
</style>
