<template>
  <div class="presentation-section">
    <h2 class="presentation-section__title">
      {{ $t('name') }}
    </h2>
    <span class="presentation-section__title--sub">
      {{ $t('presentationDescrption') }}
    </span>
    <div class="presentation-section__features">
      <div
        v-for="(feature, index) in features"
        :key="index"
        class="presentation-section__features__item"
        :style="{ backgroundColor: feature.color }"
      >
        <div class="presentation-section__features__item--index">
          <span>{{index + 1}}</span>
        </div>
        <span class="presentation-section__features__item--label">
          {{ feature.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationSection',
  computed: {
    features() {
      return [
        { color: '#101117', label: this.$t('presentationFirstParagraph') },
        { color: '#1d1f2a', label: this.$t('presentationSecondParagraph') },
        { color: '#272c3a', label: this.$t('presentationThirdParagraph') },
      ];
    }
  },
}
</script>

<style lang="scss" scoped>
.presentation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: black;

  &__title {
    font-size: 40px;
    font-weight: 600;
    color: white;
    align-self: flex-start;
    margin-left: 30px;
    margin-bottom: 10px;

    &--sub {
      font-size: 30px;
      font-weight: 400;
      color: white;
      align-self: flex-start;
      margin-left: 30px;
      margin-bottom: 60px;
      margin-right: 20px;
    }
  }

  &__features {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 250px;

    @media (max-width: 865px) {
      flex-direction: column;
      height: 100%;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      color: white;
      font-size: 16px;

      @media (max-width: 865px) {
        flex-direction: row;
        align-items: flex-start;
        height: 100%;
      }

      &--index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #FABC2A;
        color: black;
        font-size: 16px;
        margin-left: 60px;
        margin-top: 60px;

        @media (max-width: 915px) {
          margin-left: 30px;
          margin-top: 30px;
          width: 40px;
          height: 36px;
        }
      }

      &--label {
        margin: 20px 35px 0 60px;

        @media (max-width: 915px) {
          margin: 20px 35px 0 50px;
        }

        @media (max-width: 865px) {
          margin: 0;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 30px;
          margin-bottom: 30px;
          width: 85%;
        }
      }
    }
  }
}
</style>