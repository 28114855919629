<template>
  <div class="information-section">
    <div class="information-section__mock-up">
      <img src="@/assets/mockup-artiste-page-two.png" :alt="$t('mockupArtistePageTwo')" />
    </div>
    <div class="information-section__description">
      <h2
        v-html="$t('informationSectionTitle')"
        class="information-section__description__title"
      />
      <span
        v-html="$t('informationSectionSubTitle')"
        class="information-section__description__title--sub"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationSection',
  methods: {
    redirectToForm() {
      window.location = '/#mail-waitlist';
    },
  }
}
</script>

<style lang="scss" scoped>
.information-section {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: black;

  @media (max-width: 865px) {
    flex-direction: column;
    padding: 40px 55px 60px 30px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    width: 100%;

    &__title {
      font-size: 40px;
      font-weight: 600;
      margin: 0;

      &--sub {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
      }
    }
  }

  &__mock-up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 60%;
      object-fit: cover;
      min-width: 215px;

      @media (max-width: 765px) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>