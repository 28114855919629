<template>
  <div class="monitoring__info">
    <span class="monitoring__info__title">{{ $t('monitoringTitle') }}</span>
    <span class="monitoring__info__text">{{ $t('contactIfNeedHelp') }}<b>contact@nohyia.com</b>.</span><br/>
    <span class="update-delete-data__content__subtitle--description">
      {{ $t('monitoringSubtitle') }}
    </span><br/>

    <span class="monitoring__info__subtitle">{{ $t('monitoringStates') }}</span>
    <span class="monitoring__info__text">
      -&nbsp;&nbsp;<b>{{ $t('monitoringStatesPending') }}</b> {{ $t('monitoringStatesPendingDescription') }}<br/>
      -&nbsp;&nbsp;<b>{{ $t('monitoringStatesAccepted') }}</b> {{ $t('monitoringStatesAcceptedDescription') }}<br/>
      -&nbsp;&nbsp;<b>{{ $t('monitoringStatesRefused') }}</b> {{ $t('monitoringStatesRefusedDescription') }}<br/>
    </span><br/>

    <span>{{ $t('monitoringStatesDescription') }}</span><br/>

    <span class="monitoring__info__subtitle">{{ $t('monitoringCritariasTitle') }}</span>
    <span>
      -&nbsp;&nbsp;{{ $t('monitoringCritariasStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('monitoringCritariasStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('monitoringCritariasStepThree') }}<br/>
    </span><br/>

    <span class="monitoring__info__subtitle">{{ $t('monitoringFollowTitle') }}</span>
    <span>
      -&nbsp;&nbsp;{{ $t('monitoringFollowStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('monitoringFollowStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('monitoringFollowStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('monitoringFollowStepFour') }}<br/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MonitoringInfo',
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.monitoring {
  &__info {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    font-size: 14px;

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
</style>