<template>
  <div class="select-help__list">
    <div
      v-for="(option, index) in helpOptions"
      :key="index"
      class="select-help__list__card"
      @click="$router.push({ name: option.route })"
    >
      <span>{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectHelp',
  data() {
    return {
      helpOptions: [
        { name: this.$t('selectHelpDeleteAccount'), route: 'deleteAccount' },
        { name: this.$t('selectHelpUpdateInfos'), route: 'updateDeleteData' },
        { name: this.$t('selectHelpSignal'), route: 'signalAccount' },
        { name: this.$t('selectHelpBlockAccount'), route: 'blockAccount' },
        { name: this.$t('selectHelpMonitoring'), route: 'monitoringInfo' },
      ],
    };
  }
}
</script>

<style lang="scss">
.select-help {
  &__list {
    padding: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &__card {
      border-radius: 5px;
      border: solid 2px black;
      margin-bottom: 25px;
      padding: 40px;
    }
  }
}
</style>