<template>
  <div class="footer">
    <div class="footer__left-section">
      <span class="footer__click-event clickable" @click="goToTermOfUser">{{ $t('cgu') }}</span>
      <span class="footer__click-event clickable" @click="goToConfidentiality">{{ $t('politicalConfidentiality') }}</span>
    </div>
    <span class="footer__click-event clickable" @click="goToHelp">{{ $t('helpCenter') }}</span>
    <span>© 2024 - All rights reserved</span>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
  methods: {
    goToConfidentiality() {
      this.$router.push('/confidentiality/political');
    },
    goToTermOfUser() {
      this.$router.push('/confidentiality/cgu');
    },
    goToHelp() {
      this.$router.push('/help');
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 15px 80px;
  background-color: rgb(16, 17, 23);
  color: white;
  font-size: 12px;
  width: -webkit-fill-available;

  .clickable:hover {
    text-decoration: underline;
  }

  &__left-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  @media (max-width: 650px) {
    padding: 15px 30px;
  }

  & > span {
    text-align: center;
  }

  &__click-event {
    cursor: pointer;
  }
}
</style>