<template>
  <div class="confidentiality-politic">
    <div class="confidentiality-politic__header">
      <div
        class="confidentiality-politic__fixed-header"
        @click="$router.push('/')"
      >
        <span class="confidentiality-politic__fixed-header__logo">{{ $t('name') }}</span>
      </div>
      <span>{{ $t('politicalConfidentiality') }}</span>
    </div>
    <div class="confidentiality-politic__content">
      <div class="confidentiality-politic__panel">
        <span class="confidentiality-politic__panel--summary">{{ $t('summary') }}</span>
        <span
          v-for="(politic, index) in politics"
          :key="index"
          class="confidentiality-politic__panel--label"
          @click="expandCollapse(index)"
        >
          {{politic.label}}
        </span>
      </div>
      <div class="confidentiality-politic__body">
        <div
          v-for="(politic, index) in politics"
          :key="index"
          :id="`collapse-${index}`"
          class="confidentiality-politic__collapse"
        >
          <div
            :id="`confidentiality-politic__collapse__title-${index}`"
            class="confidentiality-politic__collapse__title"
            @click="expandCollapse(index)"
          >
            <span>{{politic.label}}</span>
            <span>+</span>
          </div>
          <div
            :id="`collapse-container-${index}`"
            class="confidentiality-politic__collapse__container expend"
          >
            <div
              class="confidentiality-politic__collapse__content"
            >
              <span v-html="politic.value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfidentialityPolitic',
  computed: {
    politics() {
      return [
        { label: this.$t('politicalConfidentialityPage.who.label'), value: this.$t('politicalConfidentialityPage.who.description') },
        { label: this.$t('politicalConfidentialityPage.collectedData.label'), value: this.$t('politicalConfidentialityPage.collectedData.description')},
        { label: this.$t('politicalConfidentialityPage.whyNeedData.label'), value: this.$t('politicalConfidentialityPage.whyNeedData.description') },
        { label: this.$t('politicalConfidentialityPage.informationProtection.label'), value: this.$t('politicalConfidentialityPage.informationProtection.description') },
      ];
    }
  },
  methods: {
    expandCollapse(index) {
      const container = document.getElementById(`collapse-container-${index}`)
      const title = document.getElementById(`confidentiality-politic__collapse__title-${index}`)

      title.classList.toggle('collapse')
      container.classList.toggle('expanded')
      container.classList.toggle('collapsed')
    }
  }
}
</script>

<style lang="scss">
.confidentiality-politic {
  &__fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 40px;
      font-weight: bold;
      color: #FABC2A;
      cursor: pointer;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    background-color: #FABC2A;
    justify-content: flex-start;
    padding: 50px 20px 0 30px;

    @media (max-width: 780px) {
      display: none;
    }

    &--summary {
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 25px;
    }

    &--label {
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 95%;
  }

  &__collapse {
    border-radius: 5px;
    border: solid 2px black;
    margin-bottom: 25px;
    transition: border 0.1s ease-out;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;

      &.collapse {
        font-weight: 500;
      }
    }

    &__content {
      padding: 5px 20px 20px 20px;
    }

    &__container {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.1s ease-out;
    }
  }

  &__collapse.collapse {
    border: solid 2px #FABC2A;
    transition: border 0.2s ease-out;
  }
}

.confidentiality-politic__collapse__container.expanded {
  max-height: fit-content;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>