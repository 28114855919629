<template>
  <div style="background-color: black;">
    <HeaderSection />
    <PresentationSection />
    <InformationSection />
    <div class="separator" />
    <DiscoverPartPresentation />
    <ContactSection />
  </div>
</template>

<script>
import HeaderSection from './HeaderSection.vue';
import InformationSection from './InformationSection.vue';
import PresentationSection from './PresentationSection.vue';
import DiscoverPartPresentation from './DiscoverPartPresentation.vue';
import ContactSection from './ContactSection.vue';

export default {
  name: 'MainPage',
  components: {
    HeaderSection,
    InformationSection,
    DiscoverPartPresentation,
    PresentationSection,
    ContactSection
  }
}
</script>

<style>
.separator {
  width: 150px;
  height: 0.5px;
  margin: auto;
  background-color: #FABC2A;
  margin-bottom: 60px;
}
</style>
