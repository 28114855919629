<template>
  <div class="signal__content">
    <span class="signal__content__title">{{ $t('signalTitle') }}</span>
    <span class="signal__content__text">{{ $t('contactIfNeedHelp') }}<b>contact@nohyia.com</b>.</span><br/>
    <span
      class="update-delete-data__content__subtitle--description"
      v-html="$t('signalSubtitle')"
    />
    <br/>

    <span class="signal__content__subtitle">{{ $t('signalReasons') }}</span><br/>
    <span class="signal__content__text">
      -&nbsp;&nbsp;{{ $t('signalReasonIDontLike') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonNudity') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonHate') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonViolence') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonDiffamation') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonHateSpeech') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalReasonIntelectualProperty') }}<br/>
    </span><br/>

    <span>{{ $t('signalReasonDescription') }}</span><br/>

    <span class="signal__content__subtitle">{{ $t('signalOnFileTitle') }}</span><br/>
    <span>
      -&nbsp;&nbsp;{{ $t('signalOnFileStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalOnFileStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalOnFileStepThree') }}<br/>
    </span><br/>

    <span class="signal__content__subtitle">{{ $t('signalOnFavTitle') }}</span><br/>
    <span>
      -&nbsp;&nbsp;{{ $t('signalOnFavStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalOnFavStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalOnFavStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('signalOnFavStepFour') }}<br/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SignalAccount',
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.signal {
  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    font-size: 14px;

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
</style>
