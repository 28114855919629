<template>
  <div class="update-delete-data__content">
    <span class="update-delete-data__content__title">{{ $t('updateDeleteDataTitle') }}</span>
    <span class="update-delete-data__content__text">{{ $t('contactIfNeedHelp') }}<b>contact@nohyia.com</b>.</span>
    <span class="update-delete-data__content__text spacing">{{ $t('updateDeleteDataSubtitle') }}</span><br/>

    <span class="update-delete-data__content__subtitle">{{ $t('updateAccountTitle') }}</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;{{ $t('updateAccountStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('updateAccountStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('updateAccountStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('updateAccountStepFour') }}<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">{{ $t('updatePreviewTitle') }}</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;{{ $t('updatePreviewStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('updatePreviewStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('updatePreviewStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('updatePreviewStepFour') }}<br/>
      -&nbsp;&nbsp;{{ $t('updatePreviewStepFive') }}<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">{{ $t('deletePreviewTitle') }}</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;{{ $t('deletePreviewStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('deletePreviewStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('deletePreviewStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('deletePreviewStepFour') }}<br/>
      -&nbsp;&nbsp;{{ $t('deletePreviewStepFive') }}<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">{{ $t('unpublishPreviewTitle') }}</span>

    <span class="update-delete-data__content__text">
    -&nbsp;&nbsp;{{ $t('unpublishPreviewStepOne') }}<br/>
    -&nbsp;&nbsp;{{ $t('unpublishPreviewStepTwo') }}<br/>
    -&nbsp;&nbsp;{{ $t('unpublishPreviewStepThree') }}<br/>
    -&nbsp;&nbsp;{{ $t('unpublishPreviewStepFour') }}<br/>
    -&nbsp;&nbsp;{{ $t('unpublishPreviewStepFive') }}<br/>
    </span>
  </div>
</template>
  
  <script>
  export default {
    name: 'UpdateDeleteDataPage',
  }
  </script>
  
  <style lang="scss">
  .spacing {
    margin-top: 10px;
  }
  
  .update-delete-data {
    &__content {
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
  
      &__title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
  
      &__subtitle {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
  
      &__text {
        font-size: 14px;
      }
    }
  }
  </style>
  