<template>
  <div class="block__content">
    <span class="block__content__title">{{ $t('blockAccountTitle') }}</span>
    <span class="block__content__text">{{ $t('contactIfNeedHelp') }}<b>contact@nohyia.com</b>.</span><br/>

    <span class="block__content__text">
      {{ $t('blockAccountSubtitle') }}
    </span>
    <span>{{ $t('blockAccountDescription') }}</span>
    <span>{{ $t('blockAccountContactToUnlock') }}<b>contact@nohyia.com</b>.</span>
    <span class="delete-account__content__text spacing">{{ $t('blockAccountStep') }}</span><br/>

    <span class="block__content__subtitle">{{ $t('blockAccountFromFileTitle') }}</span><br/>
    <span>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFileTitleStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFileTitleStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFileTitleStepThree') }}<br/>
    </span><br/>

    <span class="block__content__subtitle">{{ $t('blockAccountFromFavoritesTitle') }}</span><br/>
    <span>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFavoritesStepOne') }}<br/>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFavoritesStepTwo') }}<br/>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFavoritesStepThree') }}<br/>
      -&nbsp;&nbsp;{{ $t('blockAccountFromFavoritesStepFour') }}<br/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BlockAccount',
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.block {
  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    font-size: 14px;

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
</style>
