import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import App from './App.vue'
import router from './router'
import { en, es, fr } from './locales'

const messages = {
  en,
  es,
  fr
};

const userLocale = navigator.language || navigator.userLanguage;

const i18n = createI18n({
  locale: userLocale.split('-')[0],
  fallbackLocale: 'en',
  warnHtmlMessage: false,
  messages
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.mount('#app');
